<template>
  <div>
    <br />
    <CRow>
      <CButtonToolbar aria-label="Toolbar with button groups" class="col-md-12">
        <CButtonGroup>
          <CButton color="secondary" @click="AddModal = true">
            {{ $t("role.products.showcase.add_product") }}</CButton
          >
          <CButton color="primary" @click="goToProductCreateForm()">
            new product form
          </CButton>
        </CButtonGroup>
        <CButtonGroup class="mx-auto">
          <CInput
            horizontal
            v-model="search"
            :placeholder="$t('role.products.showcase.search_placeholder')"
          >
            <template #append-content
              ><CIcon name="cil-magnifying-glass" />
            </template>
          </CInput>
        </CButtonGroup>
        <CButtonGroup class="mx-1">
          <CDropdown
            right
            :togglerText="MacroCatTogglerLabel"
            color="secondary"
          >
            <CDropdownItem
              @click="
                f_structure_ptype = null;
                f_structure_ptype_label = $t('common.all');
              "
              >{{ $t("common.all") }}</CDropdownItem
            >
            <CDropdownDivider />
            <CDropdownItem
              v-for="(macro, index) in structure_ptype"
              :key="index"
              @click="
                f_structure_ptype = macro.value;
                f_structure_ptype_label = macro.label;
              "
              >{{ macro.label }}</CDropdownItem
            >
          </CDropdown>
        </CButtonGroup>
        <CButtonGroup class="mx-1" v-if="f_structure_ptype">
          <CDropdown
            right
            :togglerText="
              $t('role.products.showcase.filter.macro') +
              ': ' +
              f_structure_category_browser_p_label
            "
            color="secondary"
          >
            <CDropdownItem
              @click="
                f_structure_category_browser_p = null;
                f_structure_category_browser_p_label = $t('common.all');
              "
              >{{ $t("common.all") }}</CDropdownItem
            >
            <CDropdownDivider />
            <CDropdownItem
              v-for="(parent, index) in structure_category_browser_p"
              :key="index"
              @click="
                f_structure_category_browser_p = parent.value;
                f_structure_category_browser_p_label = parent.label;
              "
              >{{ parent.label }}</CDropdownItem
            >
          </CDropdown>
        </CButtonGroup>
        <CButtonGroup class="mx-1" v-if="f_structure_category_browser_p">
          <CDropdown
            right
            :togglerText="
              $t('role.products.showcase.filter.cat') +
              ': ' +
              f_structure_category_browser_c_label
            "
            color="secondary"
          >
            <CDropdownItem
              @click="
                f_structure_category_browser_c = null;
                f_structure_category_browser_c_label = $t('common.all');
              "
              >{{ $t("common.all") }}</CDropdownItem
            >
            <CDropdownDivider />
            <CDropdownItem
              v-for="(cat, index) in structure_category_browser_c"
              :key="index"
              @click="
                f_structure_category_browser_c = cat.value;
                f_structure_category_browser_c_label = cat.label;
              "
              >{{ cat.label }}</CDropdownItem
            >
          </CDropdown>
        </CButtonGroup>
        <CButtonGroup class="mx-1">
          <CDropdown
            right
            :togglerText="
              $t('role.products.showcase.filter.age') +
              ': ' +
              f_structure_ageclass_label
            "
            color="secondary"
          >
            <CDropdownItem
              @click="
                f_structure_ageclass_c = null;
                f_structure_ageclass_label = $t('common.all');
              "
              >{{ $t("common.all") }}</CDropdownItem
            >
            <CDropdownDivider />
            <CDropdownItem
              v-for="(ac, index) in structure_ageclass"
              :key="index"
              @click="
                f_structure_ageclass = ac.value;
                f_structure_ageclass_label = ac.label;
              "
              >{{ ac.label }}</CDropdownItem
            >
          </CDropdown>
        </CButtonGroup>
        <CButtonGroup class="mx-1">
          <CDropdown
            right
            :togglerText="
              $t('role.products.showcase.filter.logistics') +
              ': ' +
              f_structure_ltype_label
            "
            color="secondary"
          >
            <CDropdownItem
              @click="
                f_structure_ltype_c = null;
                f_structure_ltype_label = $t('common.all');
              "
              >{{ $t("common.all") }}</CDropdownItem
            >
            <CDropdownDivider />
            <CDropdownItem
              v-for="(lt, index) in structure_ltype"
              :key="index"
              @click="
                f_structure_ltype = lt.value;
                f_structure_ltype_label = lt.label;
              "
              >{{ lt.label }}</CDropdownItem
            >
          </CDropdown>
        </CButtonGroup>

        <CButtonGroup class="mx-1">
          <CDropdown right :togglerText="ActiveTogglerLabel" color="secondary">
            <CDropdownItem @click="active = true">{{
              $t("role.products.showcase.filter.state.active")
            }}</CDropdownItem>
            <CDropdownItem @click="active = false">{{
              $t("role.products.showcase.filter.state.inactive")
            }}</CDropdownItem>
            <CDropdownDivider />
            <CDropdownItem @click="active = null">{{
              $t("common.all")
            }}</CDropdownItem>
          </CDropdown>
        </CButtonGroup>
      </CButtonToolbar>
    </CRow>
    <CRow v-if="pps !== null && readiness == 6">
      <CCol md="6" v-for="(pp, index) in pps" :key="index">
        <ProductsListProduct v-model="pps[index]" />
      </CCol>
    </CRow>
    <div v-else class="loading">
      <CSpinner color="secondary" size="lg" />
    </div>
    <CRow v-if="numPages > 1">
      <CCol md="12">
        <CPagination
          :active-page.sync="currentPage"
          :pages="numPages"
          responsive
        />
      </CCol>
    </CRow>
    <ProductsListNew v-if="readiness == 6" v-model="AddModal" />
  </div>
</template>

<script>
import { GetDataManager, ApiCall } from "../../../ds/index";
import { Query, Predicate } from "@syncfusion/ej2-data";
import GetOptions from "../../../common/form/options";
import ProductsListProduct from "./ProductsListProduct";
import ProductsListNew from "./ProductsListNew";

export default {
  data() {
    return {
      currentPage: 1,
      numPages: 1,
      AddModal: false,
      pps: null,
      search: "",
      active: null,
      awaitingSearch: false,
      readiness: 0,
      structure_ptype: [],
      f_structure_ptype: null,
      f_structure_ptype_label: this.$t("common.all"),
      structure_ageclass: [],
      f_structure_ageclass: null,
      f_structure_ageclass_label: this.$t("common.all"),
      structure_ltype: [],
      f_structure_ltype: null,
      f_structure_ltype_label: this.$t("common.all"),

      structure_category_browser_p_d: [],
      f_structure_category_browser_p: null,
      f_structure_category_browser_p_label: this.$t("common.all"),
      structure_category_browser_c_d: [],
      f_structure_category_browser_c: null,
      f_structure_category_browser_c_label: this.$t("common.all"),
      cur_lang: this.$i18n.locale,
    };
  },
  components: {
    ProductsListProduct,
    ProductsListNew,
  },
  watch: {
    AddModal: function (Nv) {
      if (Nv == false) {
        this.UpdateProducts();
      }
    },
    f_structure_ptype: function () {
      this.UpdateProducts();
    },
    f_structure_ageclass: function () {
      this.UpdateProducts();
    },
    f_structure_ltype: function () {
      this.UpdateProducts();
    },

    f_structure_category_browser_p: function () {
      this.UpdateProducts();
    },
    f_structure_category_browser_c: function () {
      this.UpdateProducts();
    },
    currentPage: function () {
      this.UpdateProducts();
    },
    active: function () {
      this.UpdateProducts();
    },
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.UpdateProducts();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    ActiveTogglerLabel() {
      if (this.active == true) {
        return (
          this.$t("role.products.showcase.filter.state.title") +
          ": " +
          this.$t("role.products.showcase.filter.state.active")
        );
      } else if (this.active == false) {
        return (
          this.$t("role.products.showcase.filter.state.title") +
          ": " +
          this.$t("role.products.showcase.filter.state.inactive")
        );
      }
      return (
        this.$t("role.products.showcase.filter.state.title") +
        ": " +
        this.$t("common.all")
      );
    },
    MacroCatTogglerLabel() {
      return (
        this.$t("role.products.showcase.filter.type") +
        ": " +
        this.f_structure_ptype_label
      );
    },
    structure_category_browser_p() {
      let self = this;
      // console.log(this.structure_category_browser_p_d);
      let res = this.structure_category_browser_p_d.reduce(function (map, obj) {
        if (obj.macro_id == self.f_structure_ptype) {
          map.push(obj);
        }

        return map;
      }, []);
      return res;
    },
    structure_category_browser_c() {
      let self = this;
      // console.log(this.structure_category_browser_p_d);
      let res = this.structure_category_browser_c_d.reduce(function (map, obj) {
        if (obj.parent_id == self.f_structure_category_browser_p) {
          map.push(obj);
        }

        return map;
      }, []);
      return res;
    },
  },
  methods: {
    goToProductCreateForm() {
      this.$router.push({
        name: "RoleProductsCreate",
        params: { id: this.$store.state.role.id },
      });
    },

    UpdateProducts() {
      let self = this;
      self.pps = null;
      let predicate = new Predicate("producer_id", "greaterthanorequal", 0);

      if (this.active == true) {
        predicate = predicate.and("is_active", "equal", true);
      } else if (this.active == false) {
        predicate = predicate.and("is_active", "equal", false);
      }
      if (this.f_structure_ptype) {
        predicate = predicate.and(
          "category__macrocategory_id",
          "equal",
          this.f_structure_ptype
        );
      }
      if (this.f_structure_category_browser_p) {
        predicate = predicate.and(
          "category__parent_id",
          "equal",
          this.f_structure_category_browser_p
        );
      }
      if (this.f_structure_category_browser_c) {
        predicate = predicate.and(
          "category_id",
          "equal",
          this.f_structure_category_browser_c
        );
      }
      if (this.f_structure_ageclass) {
        predicate = predicate.and(
          "ageclass_id",
          "equal",
          this.f_structure_ageclass
        );
      }
      if (this.f_structure_ltype) {
        predicate = predicate.and("ptype_id", "equal", this.f_structure_ltype);
      }
      let query = new Query().where(predicate);
      if (!this.search == "") {
        query = query.search(this.search, [
          "translations." + this.$i18n.locale + ".name",
          "translations." + this.$i18n.locale + ".description",
        ]);
      }
      query = query.page(this.currentPage, 12);
      // Update lots info
      ApiCall(
        GetDataManager("role_producer_products", [this.$store.state.role.id]),
        query,
        function (e) {
          self.numPages = Math.ceil(e.actual.Count / 12);
          self.pps = e.result;
        }
      );
    },
  },
  created() {
    this.UpdateProducts();
    let self = this;
    GetOptions({
      dm: GetDataManager("structure_ptype"),
      query_cache: "structure_ptype",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.structure_ptype = opts;
      this.readiness += 1;
    });
    GetOptions({
      dm: GetDataManager("structure_category_browser"),
      query: new Query().where("parent_id", "equal", null),
      query_cache: "structure_category_browser_p",
      reducer: function (map, obj) {
        map.push({
          value: obj.id,
          label: obj.translations[self.$i18n.locale].name,
          macro_id: obj.macrocategory_id,
        });
        return map;
      },
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.structure_category_browser_p_d = opts;
      this.readiness += 1;
    });
    GetOptions({
      dm: GetDataManager("structure_category_browser"),
      query: new Query().where("parent_id", "greaterthan", 0),
      query_cache: "structure_category_browser_c",
      reducer: function (map, obj) {
        map.push({
          value: obj.id,
          label: obj.translations[self.$i18n.locale].name,
          parent_id: obj.parent_id,
        });
        return map;
      },
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.structure_category_browser_c_d = opts;
      this.readiness += 1;
    });
    GetOptions({
      dm: GetDataManager("structure_ageclass"),
      query_cache: "structure_ageclass",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.structure_ageclass = opts;
      this.readiness += 1;
    });
    GetOptions({
      dm: GetDataManager("structure_ltype"),
      query: new Query(),
      query_cache: "structure_ltype",
      cur_lang: this.cur_lang,
    }).then((opts) => {
      this.structure_ltype = opts;
      this.readiness += 1;
    });
    GetOptions({
      dm: GetDataManager("structure_certification"),
      reducer: function (map, obj) {
        map.push({ value: obj.id, label: obj.name, thumb: obj.image_thumb });
        return map;
      },
      reducer_cache: "certifications_i",
    }).then(() => {
      this.readiness += 1;
    });
  },
};
</script>
