var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('CRow',[_c('CButtonToolbar',{staticClass:"col-md-12",attrs:{"aria-label":"Toolbar with button groups"}},[_c('CButtonGroup',[_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.AddModal = true}}},[_vm._v(" "+_vm._s(_vm.$t("role.products.showcase.add_product")))]),_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToProductCreateForm()}}},[_vm._v(" new product form ")])],1),_c('CButtonGroup',{staticClass:"mx-auto"},[_c('CInput',{attrs:{"horizontal":"","placeholder":_vm.$t('role.products.showcase.search_placeholder')},scopedSlots:_vm._u([{key:"append-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-magnifying-glass"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('CButtonGroup',{staticClass:"mx-1"},[_c('CDropdown',{attrs:{"right":"","togglerText":_vm.MacroCatTogglerLabel,"color":"secondary"}},[_c('CDropdownItem',{on:{"click":function($event){_vm.f_structure_ptype = null;
              _vm.f_structure_ptype_label = _vm.$t('common.all');}}},[_vm._v(_vm._s(_vm.$t("common.all")))]),_c('CDropdownDivider'),_vm._l((_vm.structure_ptype),function(macro,index){return _c('CDropdownItem',{key:index,on:{"click":function($event){_vm.f_structure_ptype = macro.value;
              _vm.f_structure_ptype_label = macro.label;}}},[_vm._v(_vm._s(macro.label))])})],2)],1),(_vm.f_structure_ptype)?_c('CButtonGroup',{staticClass:"mx-1"},[_c('CDropdown',{attrs:{"right":"","togglerText":_vm.$t('role.products.showcase.filter.macro') +
            ': ' +
            _vm.f_structure_category_browser_p_label,"color":"secondary"}},[_c('CDropdownItem',{on:{"click":function($event){_vm.f_structure_category_browser_p = null;
              _vm.f_structure_category_browser_p_label = _vm.$t('common.all');}}},[_vm._v(_vm._s(_vm.$t("common.all")))]),_c('CDropdownDivider'),_vm._l((_vm.structure_category_browser_p),function(parent,index){return _c('CDropdownItem',{key:index,on:{"click":function($event){_vm.f_structure_category_browser_p = parent.value;
              _vm.f_structure_category_browser_p_label = parent.label;}}},[_vm._v(_vm._s(parent.label))])})],2)],1):_vm._e(),(_vm.f_structure_category_browser_p)?_c('CButtonGroup',{staticClass:"mx-1"},[_c('CDropdown',{attrs:{"right":"","togglerText":_vm.$t('role.products.showcase.filter.cat') +
            ': ' +
            _vm.f_structure_category_browser_c_label,"color":"secondary"}},[_c('CDropdownItem',{on:{"click":function($event){_vm.f_structure_category_browser_c = null;
              _vm.f_structure_category_browser_c_label = _vm.$t('common.all');}}},[_vm._v(_vm._s(_vm.$t("common.all")))]),_c('CDropdownDivider'),_vm._l((_vm.structure_category_browser_c),function(cat,index){return _c('CDropdownItem',{key:index,on:{"click":function($event){_vm.f_structure_category_browser_c = cat.value;
              _vm.f_structure_category_browser_c_label = cat.label;}}},[_vm._v(_vm._s(cat.label))])})],2)],1):_vm._e(),_c('CButtonGroup',{staticClass:"mx-1"},[_c('CDropdown',{attrs:{"right":"","togglerText":_vm.$t('role.products.showcase.filter.age') +
            ': ' +
            _vm.f_structure_ageclass_label,"color":"secondary"}},[_c('CDropdownItem',{on:{"click":function($event){_vm.f_structure_ageclass_c = null;
              _vm.f_structure_ageclass_label = _vm.$t('common.all');}}},[_vm._v(_vm._s(_vm.$t("common.all")))]),_c('CDropdownDivider'),_vm._l((_vm.structure_ageclass),function(ac,index){return _c('CDropdownItem',{key:index,on:{"click":function($event){_vm.f_structure_ageclass = ac.value;
              _vm.f_structure_ageclass_label = ac.label;}}},[_vm._v(_vm._s(ac.label))])})],2)],1),_c('CButtonGroup',{staticClass:"mx-1"},[_c('CDropdown',{attrs:{"right":"","togglerText":_vm.$t('role.products.showcase.filter.logistics') +
            ': ' +
            _vm.f_structure_ltype_label,"color":"secondary"}},[_c('CDropdownItem',{on:{"click":function($event){_vm.f_structure_ltype_c = null;
              _vm.f_structure_ltype_label = _vm.$t('common.all');}}},[_vm._v(_vm._s(_vm.$t("common.all")))]),_c('CDropdownDivider'),_vm._l((_vm.structure_ltype),function(lt,index){return _c('CDropdownItem',{key:index,on:{"click":function($event){_vm.f_structure_ltype = lt.value;
              _vm.f_structure_ltype_label = lt.label;}}},[_vm._v(_vm._s(lt.label))])})],2)],1),_c('CButtonGroup',{staticClass:"mx-1"},[_c('CDropdown',{attrs:{"right":"","togglerText":_vm.ActiveTogglerLabel,"color":"secondary"}},[_c('CDropdownItem',{on:{"click":function($event){_vm.active = true}}},[_vm._v(_vm._s(_vm.$t("role.products.showcase.filter.state.active")))]),_c('CDropdownItem',{on:{"click":function($event){_vm.active = false}}},[_vm._v(_vm._s(_vm.$t("role.products.showcase.filter.state.inactive")))]),_c('CDropdownDivider'),_c('CDropdownItem',{on:{"click":function($event){_vm.active = null}}},[_vm._v(_vm._s(_vm.$t("common.all")))])],1)],1)],1)],1),(_vm.pps !== null && _vm.readiness == 6)?_c('CRow',_vm._l((_vm.pps),function(pp,index){return _c('CCol',{key:index,attrs:{"md":"6"}},[_c('ProductsListProduct',{model:{value:(_vm.pps[index]),callback:function ($$v) {_vm.$set(_vm.pps, index, $$v)},expression:"pps[index]"}})],1)}),1):_c('div',{staticClass:"loading"},[_c('CSpinner',{attrs:{"color":"secondary","size":"lg"}})],1),(_vm.numPages > 1)?_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CPagination',{attrs:{"active-page":_vm.currentPage,"pages":_vm.numPages,"responsive":""},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1):_vm._e(),(_vm.readiness == 6)?_c('ProductsListNew',{model:{value:(_vm.AddModal),callback:function ($$v) {_vm.AddModal=$$v},expression:"AddModal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }